import React from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from 'react-flexbox-grid';
import NoStepsStep1 from 'images/no-steps-step-1.png';
import ThreeStepsStep1Img from 'images/3-steps-step-1.png';
import ThreeStepsStep2Img from 'images/3-steps-step-2.png';
import ThreeStepsStep3Img from 'images/3-steps-step-3.png';
import ThreeStepsStep4Img from 'images/3-steps-step-4.png';
import FourStepsStep1Img from 'images/4-steps-step-1.png';
import FourStepsStep2Img from 'images/4-steps-step-2.png';
import FourStepsStep3Img from 'images/4-steps-step-3.png';
import FourStepsStep4Img from 'images/4-steps-step-4.png';
import FourStepsStep5Img from 'images/4-steps-step-5.png';
import './workflow-scenarios.scss';

const ResouresPage = () => {
  return (
    <Layout indication="patient" className="workflow-scenarios">
      <Seo pageTitle="Vasostrict Workflow Scenarios" />
      <div className="section-bg">
        <span style={{ display: 'none' }}>Banner Pattern Image</span>
      </div>
      <div className="body-content-container">
        <div className="body-content gutter-all">
          <h1>Simplifying Your Pharmacy Workflow Preparation</h1>
          <Row>
            <Col xs={12} md={12}>
              <div className="container-parent-vial-steps">
                <div className="container-vial-steps no-steps">
                  <div className="text-steps">
                    <p className="title-steps bold">
                      ONE Step with Ready-to-Use Bottle
                    </p>
                    <ol>
                      <li>Spike</li>
                    </ol>
                  </div>
                  <div className="container-steps-icons">
                    <img
                      className="no-steps-step-1"
                      src={NoStepsStep1}
                      alt="one step with ready-to-use bottle"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="container-versus-block gutter-all">
                <div className="versus-block">
                  <p className="bold">VS</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <div className="container-parent-vial-steps">
                <div className="container-vial-steps">
                  <div className="text-steps">
                    <p className="title-steps bold">
                      FOUR Steps to Prepare 20 Units/<nobr>100 mL IV Bag</nobr>
                    </p>
                    <ol>
                      <li>Draw medication from vial into syringe</li>
                      <li>Express medication into diluent bag</li>
                      <li>Roll to mix solution until completely mixed</li>
                      <li>Spike</li>
                    </ol>
                  </div>
                  <div className="container-steps-icons">
                    <img
                      className="three-steps-step-1"
                      src={ThreeStepsStep1Img}
                      loading="lazy"
                      alt="four steps prior to spiking to prepare 20 units per 100 mL IV bag"
                    />
                    <img
                      className="three-steps-step-2"
                      src={ThreeStepsStep2Img}
                      loading="lazy"
                      alt="four steps prior to spiking to prepare 20 units per 100 mL IV bag"
                    />
                    <img
                      className="three-steps-step-3"
                      src={ThreeStepsStep3Img}
                      loading="lazy"
                      alt="four steps prior to spiking to prepare 20 units per 100 mL IV bag"
                    />
                  </div>
                  <div className="container-steps-icons">
                    <img
                      className="three-steps-step-4"
                      src={ThreeStepsStep4Img}
                      loading="lazy"
                      alt="four steps prior to spiking to prepare 20 units per 100 mL IV bag"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="container-versus-block gutter-all">
                <div className="versus-block">
                  <p className="bold">VS</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <div className="container-parent-vial-steps">
                <div className="container-vial-steps">
                  <div className="text-steps">
                    <p className="title-steps bold">
                      FIVE Steps to Prepare 40 Units/<nobr>100 mL IV Bag</nobr>
                    </p>
                    <ol>
                      <li>
                        Draw first dose of medication* from vial into syringe
                      </li>
                      <li>
                        Draw second dose of medication* from vial into syringe
                      </li>
                      <li>Express medication into diluent bag</li>
                      <li>Roll to mix solution until completely mixed</li>
                      <li>Spike</li>
                    </ol>
                    <div className="show-desktop">
                      <p className="footnote-steps">
                        *Illustration depicts 20 units.
                      </p>
                    </div>
                  </div>
                  <div className="container-steps-icons">
                    <img
                      className="four-steps-step-1"
                      src={FourStepsStep1Img}
                      loading="lazy"
                      alt="five steps prior to spiking to prepare 40 units per 100 mL IV bag"
                    />
                    <img
                      className="four-steps-step-2"
                      src={FourStepsStep2Img}
                      loading="lazy"
                      alt="five steps prior to spiking to prepare 40 units per 100 mL IV bag"
                    />
                    <img
                      className="four-steps-step-3"
                      src={FourStepsStep3Img}
                      loading="lazy"
                      alt="five steps prior to spiking to prepare 40 units per 100 mL IV bag"
                    />
                  </div>
                  <div className="container-steps-icons">
                    <img
                      className="four-steps-step-4"
                      src={FourStepsStep4Img}
                      loading="lazy"
                      alt="five steps prior to spiking to prepare 40 units per 100 mL IV bag"
                    />
                    <img
                      className="four-steps-step-5"
                      src={FourStepsStep5Img}
                      loading="lazy"
                      alt="five steps prior to spiking to prepare 40 units per 100 mL IV bag"
                    />
                  </div>
                  <div className="footnotes show-mobile max-w-485">
                    <p className="footnote-steps">
                      *Illustration depicts 20 units.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="container-download-now-block">
                <div className="download-now-block">
                  <div className="download-now-subblock" />
                  <p className="text-for-more-info bold">
                    For more information, download our Vasostrict
                    <sup>&reg;</sup> Ready-to-Use One-pager
                  </p>
                  <button>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="/pdfs/PSP-VS-05035_Vasostrict_Sell_sheet.pdf"
                    >
                      Download Now
                    </a>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default ResouresPage;
